<template>
  <!-- 智慧交通 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧交通</div>
      <!-- <div class="title-p">
        未来智慧园区，融合新一代信息与通信技术，迅捷信息采集、高速信息传输、高度集中计算、智能事务处理和无所不在的服务提供能力，实现园区内及时、互动、整合的信息感知、传递和处理，以提高园区产业集聚能力、企业经济竞争力、园区可持续发展为目标的先进园区发展理念。
      </div> -->
    </div>
    <div class="naverbar">
      <div class="naverbarheader">
        <div class="navitem">
          <div
            v-for="(item, i) in navheader"
            :key="i"
            class="navertit"
            :class="{ porent: item.id == creen }"
            @click="onclick(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="naverbarbox">
        <div class="naverbarboxcen">
          <div class="naverimg"><img :src="navheader[creen].img" /></div>
          <div class="navdeas">
            <p>{{ navheader[creen].deas }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">平台架构</div>
      <!-- <img src="../../assets/image/parkImg/组 255.png" alt="" width="100%" /> -->

      <img
        v-show="creen == 0"
        src="../../assets/image/parkImg/zhihi1.png"
        alt=""
        width="100%"
      />
      <img
        v-show="creen == 1"
        src="../../assets/image/parkImg/zhihi2.png"
        alt=""
        width="100%"
      />
      <img
        v-show="creen == 2"
        src="../../assets/image/parkImg/zhihi3.png"
        alt=""
        width="100%"
      />
      <img
        v-show="creen == 3"
        src="../../assets/image/parkImg/zhihi4.png"
        alt=""
        width="100%"
      />
    </div>
    <div v-show="creen == 0" class="function">
      <div class="function-title">功能场景</div>
      <!-- <div class="scenes-box">
        <div class="scenes-bg"></div>
        <div class="scenes-text">
          <div class="title">
            同步监测党建、安监、经济、治安、交通、环境等多维园区辖区常态领域运行
          </div>
          <div class="text-box">
            <div class="text-box-item">
              <p>立案率</p>
              <p>处置率</p>
              <p>按期处置率</p>
              <p>高发问题</p>
              <p>来源分析</p>
            </div>
            <div class="text-box-item">
              <p>有效报案数</p>
              <p>警情分布</p>
              <p>园区刷卡数</p>
            </div>
            <div class="text-box-item">
              <p>交通路况</p>
              <p>公共自行车</p>
              <p>停车泊位</p>
              <p>公交车</p>
              <p>地铁</p>
            </div>
            <div class="text-box-item">
              <p>地区生产总值</p>
              <p>规上工业产值</p>
              <p>工业增加值</p>
              <p>固定资产投资</p>
            </div>
            <div class="text-box-item">
              <p>AQI指数</p>
              <p>主要污染物</p>
              <p>PM2.5</p>
              <p>企业废水排放</p>
              <p>企业废气排放</p>
            </div>
            <div class="text-box-item">
              <p>危险源</p>
              <p>防护目标</p>
              <p>避难场所</p>
              <p>物资装备</p>
              <p>应急值守</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-show="creen == 0" class="scene">
      <h1></h1>
      <div class="img2">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">交通数字孪生</div>
          <div class="sceneContent">
            利用交通工程模型、交通传感器、历史和实时数据，集交通工程学、人工智能、大数据等多学科、多尺度、多维度、多概率的数字交通还原和仿真过程，以全息视觉交通感知传感器技术和数据为基础，融合GPS、微波雷达、地感、CO/VI、气象等多传感器数据构建的全息交通数字孪生系统。
          </div>
        </div>
      </div>
      <div class="img3">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">突发事件管理</div>
          <div class="sceneContent">
            基于多种前端视频监控、感知设备实现全域数字化道路事件实时感知，可对事件准确性进行人工校核，在根据预案对事件进行处置后，可对相应事件的处置状态进行人工标定
          </div>
        </div>
      </div>
      <div class="img4">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">事件预警管理</div>
          <div class="sceneContent">
            搭建预警算法模型，构建预警规则，根据视频监控、传感器回传的数据进行解析和分析，触发预警规则的数据，形成告警事件，实现在线事件预警
          </div>
        </div>
      </div>
      <div class="img5">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">事件协同监管</div>
          <div class="sceneContent">
            通过预警规则生成的事件列表，创建事件工单，提交事件，进入流转环节，牵头部门、协同部门，对事件进行处置。完成后对事件工单分类统计，并提取出热度分类，以及处置效率。
          </div>
        </div>
      </div>
      <div class="img6">
        <div class="sceneBox">
          <div class="sceneIcon icon5"></div>
          <div class="sceneTitle">交通信息发布</div>
          <div class="sceneContent">
            结合公路上诱导屏、语音广播、护栏防撞诱导等硬件设备及移动端，对交通拥堵、施工信息、封道限流、车辆异常行为、安全事件实时预警、设备告警信息发布
          </div>
        </div>
      </div>
      <div class="img7">
        <div class="sceneBox">
          <div class="sceneIcon icon6"></div>
          <div class="sceneTitle">事件上报</div>
          <div class="sceneContent">
            司乘人员发现交通事件、公路安全隐患等，拍照一键上报，实现社会公众参与公共交通管理中，查询事件处置处理进度、处理意见和状态，价对上报事件处置处理结果进行评价。对指挥中心的处置处理效率、结果，进行有效的监管。
          </div>
        </div>
      </div>
      <div class="img8">
        <div class="sceneBox">
          <div class="sceneIcon icon7"></div>
          <div class="sceneTitle">交通信息查询</div>
          <div class="sceneContent">
            公众人员出行前、出行过程中可查询公路实时路况信息，包括道路拥堵等情况，对公路事故情况实时提醒，实时更新事故处理情况，以及气温、能见度、湿度等信息等气象信息，为司乘人员出行提供有效的信息支撑。
          </div>
        </div>
      </div>
    </div>
    <div class="creentwo function" v-show="creen == 1">
      <div class="function-title">场景目标</div>

      <div class="one ones">
        <div class="listright">
          <div class="listrighttit">数据横向打通，提高工作效率</div>
          <div class="listrightcate">
            通过管理系统的应用不仅完成了项目部内部组织体系、施工计划、资金流向以及流程审批等多种数据的打通，同时联通了项目部与公司、业主和相关方的各个协作方，贯通了施工的上下游管理链条，实现了数据的统一管理和统筹分析，有效地提高了项目管理能力和系统能力。
          </div>
        </div>
        <div class="listleft">
          <img src="../../assets/image/parkImg/2gfrz1vs.png" alt="" />
          <!--  <img src="../../assets/image/parkImg/2gcciu63.png" alt="" /> -->
          <!--  <img src="../../assets/image/parkImg/t5957tmk.png" alt="" /> -->
          <!--  <img src="../../assets/image/parkImg/0cjp4dka.png" alt="" /> -->
          <!--  <img src="../../assets/image/parkImg/54klbbfn.png" alt="" /> -->
        </div>
      </div>
      <div class="one">
        <div class="listleft">
          <img src="../../assets/image/parkImg/2gcciu63.png" alt="" />
        </div>
        <div class="listright">
          <div class="listrighttit">物联数据互通，打破数据孤岛</div>
          <div class="listrightcate">
            将原本分散在多个厂家、多个应用、多个系统的物联数据汇聚到统一的智慧工程平台中，打破了智慧工程应用碎片化的现状，有效地消除数据孤岛，对于项目智慧工程设备的统一监管、预警信息的集中管控提供工具，提升应对响应能力
          </div>
        </div>
      </div>
      <div class="one ones">
        <div class="listright">
          <div class="listrighttit">建立相关标准，形成预警体系</div>
          <div class="listrightcate">
            通过智能建造系统平台的建立，形成企业自身的智能建造相关技术、数据标准/导则，生成项目日常生产建设中的各项预警信息，为项目日常管理提供依据和警示，做到提前预警防范未然。
          </div>
        </div>
        <div class="listleft">
          <img src="../../assets/image/parkImg/t5957tmk.png" alt="" />
        </div>
      </div>
      <div class="one">
        <div class="listleft">
          <img src="../../assets/image/parkImg/2gcciu63.png" alt="" />
        </div>
        <div class="listright">
          <div class="listrighttit">建立数字化平台，构筑竞争新优势</div>
          <div class="listrightcate">
            智能建造数字化平台，通过物联网设备和互联网技术，依托云底座，提高了集团公司数字化、智慧建造意识，培育基于数据驱动的企业新型能力，构筑公司竞争新优势。
          </div>
        </div>
      </div>
      <div class="one ones">
        <div class="listright">
          <div class="listrighttit">完善项目监管体系，形成标准化业务流程</div>
          <div class="listrightcate">
            通过数字平台应用，利用数字化技术和手段，将项目施工的全流程数据串联，统一集团的建设项目安全质量监管体系，形成标准化建设业务流程。
          </div>
        </div>
        <div class="listleft">
          <img src="../../assets/image/parkImg/0cjp4dka.png" alt="" />
        </div>
      </div>
    </div>
    <div v-show="creen == 2" class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div v-show="creen == 2" class="objective">
      <div class="objectivekap" v-for="(item, i) in tablist" :key="i">
        <div class="obgdbox">
          <img :src="item.icon" alt="" />
          {{ item.tit }}
        </div>
        <div class="titdeD">
          {{ item.dead }}
        </div>
      </div>
    </div>
    <div v-show="creen == 3" class="function">
      <div class="function-title">场景目标</div>
    </div>
    <div v-show="creen == 3" class="functionone">
      <div class="creen4rt">
        <img src="../../assets/image/parkImg/back3.png" alt="" />
      </div>
      <div class="creen4left">
        <div class="creen4leftlist">
          <span></span><span>提升城市公共交通运行监测水平</span>
        </div>
        <div class="creen4leftlist">
          <span></span><span>提升城市公共交通企业智能化调度水平</span>
        </div>
        <div class="creen4leftlist">
          <span></span><span>提升出行信息服务水平</span>
        </div>
        <div class="creen4leftlist">
          <span></span><span>提升行业监管水平</span>
        </div>
        <div class="creen4leftlist">
          <span></span><span>提升安全保障水平</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      creen: 0,
      navheader: [
        {
          id: 0,
          name: "智慧公路综合管理",
          deas: "基于对公路、高架等三维地理信息的采集与建模，进行交通公路资产、设施设备数字化管理，并进行交通数字孪生，实现在基于孪生的交通仿真、事件预警处置、信息服务于管理决策功能。",
          img: require("../../assets/image/parkImg/jt1.png"),
        },
        {
          id: 1,
          name: "集团数字化建设项目管理",
          deas: "全方位提高集团整体的项目管理水平，增强企业对施工安全、质量、现场、材料等方面的管理力度。通过实时管理、远程管理、智能管理等精细化管理手段，将项目现场管理与企业管理融合在一起，实现现场施工管理模式的创新。",
          img: require("../../assets/image/parkImg/jt2.png"),
        },
        {
          id: 2,
          name: "智慧出行管理服务",
          deas: "改进乘客出行信息服务方式，构建内容丰富、形式多样、及时可靠的出行信息服务体系，提高公共交通系统的出行信息服务能力。提升公交运行主动安全防范能力，基于车载设备与公安等部门共享数据，减少安全隐患。",
          img: require("../../assets/image/parkImg/jt3.png"),
        },
        {
          id: 3,
          name: "标准化场站安全管控",
          deas: "以战场管理“看得见、听得到、调的动”为目标，实现战场管理业务流程串联，提升战场管理水平和服务水平；规范战场终端和数据接入规则，为后续全面规范化、标准化战场建设奠定坚实基础。",
          img: require("../../assets/image/parkImg/jt4.png"),
        },
      ],
      tablist: [
        {
          tit: "改进",
          dead: "改进行业运行监管方式，完善公交运行状态与数据采集体系，提升行业及企业智能调度与运营管理效率，提高城市公共交通行业动态监测、指挥协调、服务监管、异常预警和辅助决策效能；",
          icon: require("../../assets/image/parkImg/组 88.png"),
        },
        {
          tit: "服务",
          dead: "改进乘客出行信息服务方式，构建内容丰富、形式多样、及时可靠的出行信息服务体系，提高公共交通系统的出行信息服务能力。",
          icon: require("../../assets/image/parkImg/组 605.png"),
        },
        {
          tit: "防范",
          dead: "提升公交运行主动安全防范能力，基于车载智能终端与公安等部门共享数据，消除安全隐患。",
          icon: require("../../assets/image/parkImg/组 606.png"),
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onclick(id) {
      this.creen = id;
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    go(i) {
      if (i == 1) {
        this.$router.push("CloudServices");
      }
      if (i == 2) {
        this.$router.push("consumptionManagement");
      }
      if (i == 3) {
        this.$router.push("Stereosynthesis");
      }
      if (i == 4) {
        this.$router.push("assetManagement");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/parkImg/bg1.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .nh {
    background: url("../../assets/image/parkImg/nhbg.png") no-repeat;
    margin-top: 50px;
  }
  .naverbar {
    height: 410px;
    width: 100%;
    background: #fff;
    .naverbarheader {
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: end;
      border-bottom: 1px solid #e4e4e4;
      .navitem {
        margin-right: 160px;
        display: flex;
        justify-content: end;
        border-bottom: 1px solid #e4e4e4;
        .navertit {
          color: #888888;
          font-size: 20px;
          margin-right: 40px;
          cursor: pointer;
        }
        .porent {
          color: #333333;
          font-size: 20px;
          cursor: pointer;
          position: relative;
          &::after {
            display: inline-block;
            position: absolute;
            content: "";
            width: 74px;
            height: 2px;
            background: #3651d8;
            top: 77px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    .naverbarbox {
      height: 250px;
      width: 80%;
      display: flex;
      margin-left: 10%;
      margin-top: 20px;
      .naverbarboxcen {
        display: flex;
        .naverimg {
          width: 400px;
          height: 250px;
          img {
            width: 400px;
            height: 250px;
          }
        }
        .navdeas {
          width: 55%;
          margin-top: 40px;
          color: #6b6b6b;
          font-size: 18px;
          margin-left: 50px;
        }
      }
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }

    .scenes-box {
      .scenes-bg {
        width: 100%;
        height: 420px;
        background: url("../../assets/image/parkImg/scenes.png") no-repeat;
        background-size: 100%;
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;
      height: 500px;
      display: flex;
      background-color: #fff;
      .left {
        width: 65%;
        height: 500px;
        background: url("../../assets/image/parkImg/services.png") no-repeat;
        background-size: 100%;
      }
      .right {
        width: 30%;
        padding: 6%;
        box-sizing: border-box;
        div {
          font-size: 24px;
          color: #6b6b6b;
        }
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
    .nh-box {
      display: flex;
      text-align: center;
      justify-content: space-between;

      .nh-a {
        width: 32%;
        .nh-title {
          width: 100%;
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          color: #3651d8;
          background-color: #d6ddff;
        }
        .nh-grid {
          display: flex;
          margin: 3% 0;
          .grid-left {
            width: 60%;
            height: 200px;
            background: url("../../assets/image/parkImg/nh1.png");
            padding: 5%;
            text-align: left;
            box-sizing: border-box;
            span {
              color: #fff;
              font-size: 20px;
            }
          }
          .grid-right {
            width: 40%;
            height: 200px;
            padding: 3%;
            display: flex;
            text-align: left;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            background-color: #fff;
            .grid-line {
              font-size: 0.8vw;
              color: #6b6b6b;
              height: 25%;
              i {
                vertical-align: middle;
                display: inline-block;
                width: 6px;
                height: 6px;
                margin: 0 5%;
                background: #3651d8;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 80%;
              }
            }
          }
        }
        .bg2 {
          background: url("../../assets/image/parkImg/nh2.png") no-repeat !important;
        }
        .bg3 {
          background: url("../../assets/image/parkImg/nh3.png") no-repeat !important;
        }
        .bg4 {
          background: url("../../assets/image/parkImg/nh4.png") no-repeat !important;
        }
        .bg5 {
          background: url("../../assets/image/parkImg/nh5.png") no-repeat !important;
        }
        .bg6 {
          background: url("../../assets/image/parkImg/nh6.png") no-repeat !important;
        }
      }
    }
    .security-text {
      font-size: 1.4vw;
      color: #6b6b6b;
      margin-left: 25%;
      // transform: translateX(-50%);
      p {
        margin: 1% 0;
      }
    }
    .security-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      margin-top: 40px;
      .security-item {
        width: 24%;
        img {
          width: 100%;
        }
        div {
          margin: 30px 0;
        }
      }
    }
  }
}
.scene {
  padding: 0 10%;
  display: flex;
  margin: 0 0 50px;
  overflow: hidden;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      padding-top: 60px;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 40px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 0px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 160px;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      margin: 10px 10px;
      z-index: 99;
      display: none;
      box-sizing: border-box;
      padding: 0 5px;
      line-break: anywhere;

      > span {
        display: inline-block;
        width: 46%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: rgba(168, 168, 168, 0.5);
        border-radius: 0px;
        margin: 15px 2% 0 0;
        color: #fff;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
      }
    }
  }
}
.img2 {
  background: url("../../assets/image/parkImg/组 556.png") no-repeat;
  background-size: 101% 100%;
}
.img3 {
  background: url("../../assets/image/parkImg/组 558.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/parkImg/组 559.png") no-repeat;
  background-size: 101% 100%;
}
.img5 {
  background: url("../../assets/image/parkImg/组 560.png") no-repeat;
  background-size: 100% 100%;
}
.img6 {
  background: url("../../assets/image/parkImg/组 561.png") no-repeat;
  background-size: 101% 100%;
}
.img7 {
  background: url("../../assets/image/parkImg/组 562.png") no-repeat;
  background-size: 101% 100%;
}
.img8 {
  background: url("../../assets/image/parkImg/组 563.png") no-repeat;
  background-size: 101% 100%;
}
.icon1 {
  background: url("../../assets/image/parkImg/组 683.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/parkImg/组 693.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/parkImg/组 692.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/parkImg/组 691.png") no-repeat;
  background-size: 100% 100%;
}
.icon5 {
  background: url("../../assets/image/parkImg/组 690.png") no-repeat;
  background-size: 100% 100%;
}
.icon6 {
  background: url("../../assets/image/parkImg/组 689.png") no-repeat;
  background-size: 100% 100%;
}
.icon7 {
  background: url("../../assets/image/parkImg/组 688.png") no-repeat;
  background-size: 100% 100%;
}
.creentwo {
  background: #fff;
  min-height: 1000px;
  padding: 0 10%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.one {
  // width: 5;

  height: 480px;
  display: flex;
  justify-content: space-between;
  .listright {
    width: 830px;
    height: 380px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .listrighttit {
      font-size: 26px;
      color: #333333;
      line-height: 80px;
      font-weight: bold;
    }
    .listrightcate {
      font-size: 18px;

      color: #6b6b6b;
    }
  }
  .listleft {
    width: 594px;
    height: 380px;
    img {
      width: 594px;
      height: 380px;
    }
  }
}
.ones {
  margin-top: 80px;
}
.objective {
  background: #fff;
  margin-bottom: 40px;
  height: 288px;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .objectivekap {
    width: 32%;
    height: 200px;
    background: url("../../assets/image/parkImg/矩形\ 280.png") no-repeat;
    background-size: 100% 100%;
    .obgdbox {
      position: relative;
      width: 180px;
      height: 60px;
      background: #c7b090;
      opacity: 1;
      border-radius: 0px 0px 30px 0px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 0px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    .titdeD {
      margin: 30px;
      font-size: 16px;
      color: #6b6b6b;
    }
  }
  .objectivekap:nth-child(2) {
    background: url("../../assets/image/parkImg/矩形 281.png") no-repeat;
    background-size: 100% 100%;
  }
  .objectivekap:nth-child(3) {
    background: url("../../assets/image/parkImg/矩形 281.png") no-repeat;
    background-size: 100% 100%;
  }
}
.functionone {
  display: flex;
  flex-direction: row;
  margin: 0 10%;
  margin-bottom: 40px;
  .creen4rt {
    width: 50%;
    height: 444px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .creen4left {
    width: 50%;
    background-color: #ffffff;
    .creen4leftlist {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      span:nth-child(1) {
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #e9e9e9;
        transform: rotate(45deg);
        margin: 50px;
      }
      span:nth-child(2) {
        color: #6b6b6b;
      }
    }
    .creen4leftlist:nth-child(1) {
      margin-top: 60px;
    }
  }
}
</style>
